import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    //font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    //font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #root {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    //font-family: Georgia, Times, 'Times New Roman', serif;
    //line-height: 1.5em;
  }
  
  //todo find a better way
  .tree-selector-drop-down {
		max-height: 300px !important;  
  }

  //below style is to allow x overflow when a user is resizing the window too much
  .ant-layout.ant-layout-has-sider > .ant-layout, .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: inherit;
  }

  .ant-dropdown-menu {
        -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.40);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.40);
  }

`;

export default GlobalStyle;
