import _ from "lodash";
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllRequest, prepareSecurityVersionRequest } from './securityVersions.parsers';
import { NOOP } from 'containers/App/app.constants';

const extraAsyncActions = [
	{
		type: 'uploadSecurityVersion',
		requestPrepareFn: prepareSecurityVersionRequest,
		setStateDataFn: NOOP
	},
]

const securityVersionEntitySlice = createNewEntitySlice({
	name: ENTITY_TYPES.SECURITY_VERSIONS,
	parseFetchAllRequest,
	extraAsyncActions
});

const { reducer, actions, selectors } = securityVersionEntitySlice;
const {
	fetchAll: fetchSecurityVersions,
	fetchAllPending: fetchSecurityVersionsPending,
	fetchAllSuccess: fetchSecurityVersionsSuccess,
	fetchAllFailure: fetchSecurityVersionsFailure,

	fetchById: fetchSecurityVersion,
	fetchByIdPending: fetchSecurityVersionPending,
	fetchByIdSuccess: fetchSecurityVersionSuccess,
	fetchByIdFailure: fetchSecurityVersionFailure,

	create: createSecurityVersion,
	createPending: createSecurityVersionPending,
	createSuccess: createSecurityVersionSuccess,
	createFailure: createSecurityVersionFailure,

	update: updateSecurityVersion,
	updatePending: updateSecurityVersionPending,
	updateSuccess: updateSecurityVersionSuccess,
	updateFailure: updateSecurityVersionFailure,

	delete: deleteSecurityVersion,
	deletePending: deleteSecurityVersionPending,
	deleteSuccess: deleteSecurityVersionSuccess,
	deleteFailure: deleteSecurityVersionFailure,

	uploadSecurityVersion,
	uploadSecurityVersionPending,
	uploadSecurityVersionSuccess,
	uploadSecurityVersionFailure,
} = actions;

export const securityVersionEntityActions = {
	fetchSecurityVersions,
	fetchSecurityVersionsPending,
	fetchSecurityVersionsSuccess,
	fetchSecurityVersionsFailure,

	fetchSecurityVersion,
	fetchSecurityVersionPending,
	fetchSecurityVersionSuccess,
	fetchSecurityVersionFailure,

	createSecurityVersion,
	createSecurityVersionPending,
	createSecurityVersionSuccess,
	createSecurityVersionFailure,

	updateSecurityVersion,
	updateSecurityVersionPending,
	updateSecurityVersionSuccess,
	updateSecurityVersionFailure,

	deleteSecurityVersion,
	deleteSecurityVersionPending,
	deleteSecurityVersionSuccess,
	deleteSecurityVersionFailure,

	uploadSecurityVersion,
	uploadSecurityVersionPending,
	uploadSecurityVersionSuccess,
	uploadSecurityVersionFailure,
};


const {
	selectAll: selectSecurityVersions,
	selectFetchAllPending: selectSecurityVersionsPending,
	createSelectByIdOrIds: createSelectSecurityVersionsByIdOrIds,
	selectCreatePending: selectCreateSecurityVersionPending,
	selectFetchByIdPending: selectFetchSecurityVersionPending,
} = selectors;


export const securityVersionEntitySelectors = {
	selectSecurityVersions,
	selectSecurityVersionsPending,
	createSelectSecurityVersionsByIdOrIds,
	selectCreateSecurityVersionPending,
	selectFetchSecurityVersionPending,
	selectUploadSecurityVersionPending: (state) => {
		return _.get(state, "entities." + ENTITY_TYPES.SECURITY_VERSIONS + ".uploadSecurityVersionLoading");
	}
};

export default reducer;