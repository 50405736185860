import UserManagement from '../libs/client-user-management';


export const hasPermission = UserManagement.hasPermission.bind(UserManagement);

export const PERMISSIONS = {
	//Users
	UI_VIEW_USERS: 'UI_VIEW_USERS',
	UI_CREATE_USER: 'UI_CREATE_USER',
	UI_UPDATE_USER: 'UI_UPDATE_USER',

	//Devices
	UI_VIEW_DEVICES: 'UI_VIEW_DEVICES',
	UI_CREATE_DEVICE: 'UI_CREATE_DEVICE',
	UI_UPDATE_DEVICE: 'UI_UPDATE_DEVICE',
	UI_DISABLE_DEVICE: 'UI_DISABLE_DEVICE',
	UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE: 'UI_EDIT_CUSTOMER_FOR_ACTIVE_DEVICE',
	UI_ASSIGN_USERS_FOR_DEVICE: 'UI_ASSIGN_USERS_FOR_DEVICE',
	UI_EDIT_CUSTOMER_FOR_DEVICE: 'UI_EDIT_CUSTOMER_FOR_DEVICE',

	//FOTA
	UI_VIEW_FIRMWARE_MANAGER_TAB: 'UI_VIEW_FIRMWARE_MANAGER_TAB',
	UI_VIEW_GROUPS: 'UI_VIEW_GROUPS',
	UI_CREATE_GROUP: 'UI_CREATE_GROUP',
	UI_UPDATE_GROUP: 'UI_UPDATE_GROUP',
	UI_ASSIGN_DEVICES_TO_GROUP: 'UI_ASSIGN_DEVICES_TO_GROUP',
	UI_PUBLISH_GROUP_VERSIONS: 'UI_PUBLISH_GROUP_VERSIONS',
	UI_VIEW_SOFTWARE_VERSIONS: 'UI_VIEW_SOFTWARE_VERSIONS',
	UI_UPLOAD_SOFTWARE_VERSION: 'UI_UPLOAD_SOFTWARE_VERSION',
	UI_UPDATE_SOFTWARE_VERSION: 'UI_UPDATE_SOFTWARE_VERSION',
	UI_VIEW_LUMX_VERSIONS: 'UI_VIEW_LUMX_VERSIONS',
	UI_UPLOAD_LUMX_VERSION: 'UI_UPLOAD_LUMX_VERSION',
	UI_UPDATE_LUMX_VERSION: 'UI_UPDATE_LUMX_VERSION',
	UI_UPLOAD_V2C: 'UI_UPLOAD_V2C',
	UI_VIEW_SECURITY_VERSIONS: 'UI_VIEW_SECURITY_VERSIONS',

	//LOGS
	UI_MANAGE_LOGS: 'UI_MANAGE_LOGS',

	//ALARMS
	UI_VIEW_ALARMS: "UI_VIEW_ALARMS",

	//QLICK
	UI_VIEW_QLIK_MENU: "UI_VIEW_QLIK_MENU",
};