import React from 'react';
import { Typography } from 'antd'
import EntityGrid from 'containers/Grid/components/EntityGrid';
import moment from 'moment';

const { Text } = Typography;

const GroupDevicesStatus = (props) => {
  const { groupId } = props;
  const columns = props.columns || [
    {
      key: 'device.serialNumber',
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      sorter: true
    },
    {
      key: 'device.deviceType',
      title: 'Device Type',
      dataIndex: 'deviceType',
      sorter: true
    },
    {
      key: 'device.softwareVersion',
      title: 'Curr Soft Ver',
      dataIndex: 'softwareVersion',
      sorter: true
    },
    {
      key: 'device.softwareVersionTimestamp',
      title: 'Update Date',
      dataIndex: 'softwareVersionTimestamp',
      sorter: true,
      render: (softwareVersionTimestamp) => softwareVersionTimestamp && moment(softwareVersionTimestamp).format("DD-MMM-YYYY HH:mm")
    },
    {
      key: 'device.lumxVersion',
      title: 'Curr LumX Ver',
      dataIndex: 'lumxVersion',
      sorter: true
    },
    {
      key: 'device.lumxVersionTimestamp',
      title: 'Update Date',
      dataIndex: 'lumxVersionTimestamp',
      sorter: true,
      render: (lumxVersionTimestamp) => lumxVersionTimestamp && moment(lumxVersionTimestamp).format("DD-MMM-YYYY HH:mm")
    },
    {
      key: 'device.securityVersion',
      title: 'Curr Security Ver',
      dataIndex: 'securityVersion',
      sorter: true
    },
    {
      key: 'device.securityVersionTimestamp',
      title: 'Update Date',
      dataIndex: 'securityVersionTimestamp',
      sorter: true,
      render: (lumxVersionTimestamp) => lumxVersionTimestamp && moment(lumxVersionTimestamp).format("DD-MMM-YYYY HH:mm")
    },
    {
      key: 'device.securityVersion',
      title: 'Security Ver Status',
      dataIndex: 'securityCommandMessage',
      sorter: false
    }
  ];

  const fetchGroupDevicesWrapper = (params) => {
    const paramsWithGroupId = {
      ...params,
      additionalData: {
        groupId,
      }
    }
    props.fetchGroupDevices(paramsWithGroupId)
  }

  return (
    <div>
      <GridHeader group={props.group} />

      <EntityGrid
        grid={props.grid}
        fetchData={fetchGroupDevicesWrapper}
        fetchDataPending={props.isFetchDevicesLoading}
        data={props.groupDevices}
        columns={columns}
        resetGridData={props.resetDevicesGridData}
        setGridParams={props.setDevicesGridParams}
      />

    </div>
  );
}
  ;

// component that shows group data
const GridHeader = (params) => {
  const group = params.group;
  const containerStyle = {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
  }

  return (
    <div style={containerStyle}>
      <TitleNameAndValue
        title={"Group Name"}
        value={group.groupName}
      />
      <TitleNameAndValue
        title={"Desired Software Version"}
        value={group.softwareVersion}
      />
      <TitleNameAndValue
        title={"Desired LumenisX Version"}
        value={group.lumxVersion}
      />
      <TitleNameAndValue
        title={"Desired Security Version"}
        value={group.securityVersion}
      />
    </div>
  )
}

// component for displaying name and value
const TitleNameAndValue = (params) => {
  return (
    <span>
      <Text strong>{params.title}:</Text>
      <Text style={{ marginLeft: 10 }}>{params.value}</Text>
    </span>
  )
}

export default GroupDevicesStatus;