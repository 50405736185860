import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import DataGrid from "components/DataGrid";

const scroll = { y: "calc(100vh - 270px)" };

const EntityGrid = (props) => {
  const {
    className,
    grid,
    fetchData,
    fetchDataPending,
    data,
    columns,
    resetGridData,
    GridTitle,
    setGridParams,
    isAutoRefresh = false,
    refreshIntervalInMS = 30000,
    expandedRowRender,

    toolbarComponent,

    rowSelection,
    size,
  } = props;

  useEffect(() => {
    loadData(grid, fetchData);
  }, [
    //all grid.params besides pagination total - total results is updated in pagination from response and we don't want a re-fetch
    grid.params.sorter,
    grid.params.filters,
    grid.params.pagination.current,
    grid.params.pagination.limit,
    grid.params.search,
  ]);

  useEffect(() => {
    if (isAutoRefresh) {
      const interval = setInterval(() => {
        loadData(grid, fetchData);
      }, refreshIntervalInMS);
      return () => clearInterval(interval);
    }
  });

  //componentWillUnmount
  useEffect(
    () => () => {
      resetGridData();
    },
    []
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setGridParams({ ...grid.params, pagination, sorter });
  };

  return (
    <DataGrid
      className={className}
      toolbarComponent={toolbarComponent}
      isLoading={fetchDataPending}
      data={data}
      onChange={handleTableChange}
      scroll={scroll}
      rowSelection={rowSelection}
      size={size}
      GridTitle={GridTitle}
      expandedRowRender={expandedRowRender}
      {...grid.params}
    >
      {columns.map((column, i) => {
        return <Table.Column {...column} />;
      })}
    </DataGrid>
  );
};

EntityGrid.propTypes = {
  // gridId: PropTypes.string.isRequired,
};

export const loadData = (grid, fetchData) => {
  // in the grid params filters map, we take each value (which is an array) and transform it into a joined string
  const filters = Object.keys(grid.params.filters).reduce(
    (accumulator, currentKey) => {
      const currentValue = grid.params.filters[currentKey];

      accumulator[currentKey] = Array.isArray(currentValue)
        ? currentValue.join()
        : currentValue;

      return accumulator;
    },
    {}
  );
  const params = {
    ...grid.params,
    filters,
  };

  fetchData(params);
};

export default React.memo(EntityGrid);
