import React from "react";
import FormFactory from "components/FormFactory";
import { Row, Col } from "antd";
import { withErrorBoundary } from "components/ErrorBoundary";
import FileBrowserField from "components/FormFactory/fields/FileBrowserField";
import commonMessages from "src/commonMessages";
import messages from "../messages";

const validateMessages = {
	required: '%s is required',
	array: {
		max: '%s cannot contain more than %s file'
	}
};

const Form = FormFactory.create({
	name: "uploadSecurityVersionForm",
	onFieldsChange: (props, changedFields, allFields) => {
	},
	validateMessages,
});

class UploadSecurityVersionForm extends React.Component {

	onSubmit = (securityVersionValues) => {
		this.props.uploadSecurityVersion({ payload: securityVersionValues });
	};

	onCancel = (form) => {
		this.props.hideModal();
	};

	render() {
		const { isUploadPending, intl } = this.props;
		const submitButtonText = intl.formatMessage(commonMessages.save);
		const uploadText = intl.formatMessage(messages.upload);
		return (
			<Form
				submitButtonText={submitButtonText}
				hideSubmitButton={false}
				onSubmit={this.onSubmit}
				isLoading={isUploadPending}
				hideCancelButton={false}
				onCancel={this.onCancel}
			>
				<Row gutter={24}>
					<Col span={9}>
						<FileBrowserField
							name="upload"
							label={uploadText}
							required
							disabled={false}
						/>
					</Col>
				</Row>
			</Form>
		);
	}

}

export default withErrorBoundary(UploadSecurityVersionForm);