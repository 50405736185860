import { connect } from 'react-redux';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import { DAEMON } from 'utils/constants';
import _ from 'lodash';
import { push } from 'connected-react-router';

import { loginActions } from 'pages/LoginPage/modules/login.slice';


import { selectLoginIsLoading } from 'pages/LoginPage/modules/login.selectors';
import deviceTypesSaga from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.saga';
import userGroupsSaga from 'containers/App/modules/entities/userGroups/userGroupEntity.saga';
import countriesSaga from 'containers/App/modules/entities/countries/countries.saga';
import userSaga from 'containers/App/modules/entities/users/userEntity.saga';
import alarmsSaga from 'containers/App/modules/entities/alarms/alarmsEntity.saga';
import deviceAlarmsSaga from 'containers/App/modules/entities/deviceAlarms/deviceAlarmsEntity.saga';
import groupsSaga from 'containers/App/modules/entities/groups/groupEntity.saga';
import licensesSaga from 'containers/App/modules/entities/licenses/licenseEntity.saga';
import swVersionsSaga from 'containers/App/modules/entities/swVersions/swVersionEntity.saga';
import securityVersionsSaga from 'containers/App/modules/entities/securityVersions/securityVersionEntity.saga';
import v2cSaga from 'containers/App/modules/entities/v2c/v2cEntity.saga';
import lumenisXVersionsSaga from 'containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.saga';
import filesSaga from 'containers/App/modules/entities/deviceFiles/deviceFilesEntity.saga';
import AppLayout from 'containers/App/components/AppLayout';
import { countriesSelectors } from 'containers/App/modules/entities/countries/countries.slice';
import { deviceTypesSelectors } from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import { userGroupSelectors } from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import { userEntitySelectors } from 'containers/App/modules/entities/users/userEntity.slice';
import { consentSelectors } from '../../../pages/Consent/modules/consent.slice';


const mapStateToProps = (state) => {
	const loginLoading = selectLoginIsLoading(state);
	const countriesLoading = countriesSelectors.selectCountriesPending(state);
	const deviceTypesLoading = deviceTypesSelectors.selectDeviceTypesPending(state);
	const userGroupsLoading = userGroupSelectors.selectUserGroupsPending(state);
	const fetchLoggedInUserPending = userEntitySelectors.selectFetchLoggedInUserPending(state);
	const fetchDeviceTypesHierarchyLoading = deviceTypesSelectors.selectDeviceTypesHierarchyLoading(state);
	const fetchLocationsHierarchyLoading = countriesSelectors.selectLocationsHierarchyLoading(state);
	const fetchConsentsLoading = consentSelectors.selectConsentsLoading(state);
	const consents = consentSelectors.getConsents(state);
	const areAllConsentsConfirmed = consentSelectors.getAreAllConsentsConfirmed(state);

	const appStartActionLoading =
		loginLoading ||
		countriesLoading ||
		deviceTypesLoading ||
		userGroupsLoading ||
		fetchLoggedInUserPending ||
		fetchDeviceTypesHierarchyLoading ||
		fetchLocationsHierarchyLoading ||
		fetchConsentsLoading;

	//todo so children won't be rendered when all are still undefined. basically i need to keep a separate redux var for that like appLoading = true by default so children won't render
	const isAppLoading = !_.isUndefined(appStartActionLoading) ? appStartActionLoading : true;

	const userName = `${state.userManagementReducer.firstName} ${state.userManagementReducer.lastName}`;
	return {
		isAppLoading,
		userName,
		consents,
		areAllConsentsConfirmed,
	};
};

const withConnect = connect(mapStateToProps, { ...loginActions, push });

const withDeviceTypesSaga = injectSaga({
	key: 'deviceTypesSaga',
	saga: deviceTypesSaga,
	mode: DAEMON,
});

const withCountriesSaga = injectSaga({
	key: 'countriesSaga',
	saga: countriesSaga,
	mode: DAEMON,
});

const withUserGroupsSaga = injectSaga({
	key: 'userGroupsSaga',
	saga: userGroupsSaga,
	mode: DAEMON,
});

const withUsersSaga = injectSaga({
	key: 'userSaga',
	saga: userSaga,
	mode: DAEMON,
});

const withAlarmsSaga = injectSaga({
	key: 'alarmsSaga',
	saga: alarmsSaga,
	mode: DAEMON,
});

const withDeviceAlarmsSaga = injectSaga({
	key: 'deviceAlarmsSaga',
	saga: deviceAlarmsSaga,
	mode: DAEMON,
});

const withGroupsSaga = injectSaga({
	key: 'groupsSaga',
	saga: groupsSaga,
	mode: DAEMON,
});

const withLicensesSaga = injectSaga({
	key: 'licensesSaga',
	saga: licensesSaga,
	mode: DAEMON,
});

const withSwVersionsSaga = injectSaga({
	key: 'swVersionsSaga',
	saga: swVersionsSaga,
	mode: DAEMON,
});

const withSecurityVersionsSaga = injectSaga({
	key: 'securityVersionsSaga',
	saga: securityVersionsSaga,
	mode: DAEMON,
});

const withV2CSaga = injectSaga({
	key: 'v2cSaga',
	saga: v2cSaga,
	mode: DAEMON,
});

const withLumenisXVersionsSaga = injectSaga({
	key: 'lumenisXVersionsSaga',
	saga: lumenisXVersionsSaga,
	mode: DAEMON,
});

const withFilesSaga = injectSaga({
	key: 'filesSaga',
	saga: filesSaga,
	mode: DAEMON,
});

export default compose(
	withUsersSaga,
	withDeviceTypesSaga,
	withCountriesSaga,
	withUserGroupsSaga,
	withAlarmsSaga,
	withDeviceAlarmsSaga,
	withGroupsSaga,
	withLicensesSaga,
	withSwVersionsSaga,
	withSecurityVersionsSaga,
	withV2CSaga,
	withLumenisXVersionsSaga,
	withFilesSaga,
	withConnect,
)(AppLayout);