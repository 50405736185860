import { connect } from "react-redux";
import { compose } from "redux";
import SecurityVersionMetadataPreviewModal from "pages/SecurityVersions/components/SecurityVersionMetadataPreviewModal";
import { securityVersionEntityActions, securityVersionEntitySelectors } from "containers/App/modules/entities/securityVersions/securityVersionEntity.slice";
import injectReducer from "utils/injectReducer";
import securityVersionReducer, { securityVersionSelectors } from "../modules/securityVersions.slice";

import { modalActions } from "containers/ModalRoot/modules/modal.slice";



const withReducer = injectReducer({
    key: "securityVersions",
    reducer: securityVersionReducer,
});

const mapStateToProps = (state, ownProps) => {
    return {
        securityVersionMetadataPreview: securityVersionSelectors.selectSecurityVersionMetadataPreview(state),
        isCreatePending: securityVersionEntitySelectors.selectCreateSecurityVersionPending(state),
        grid: securityVersionSelectors.selectSecurityVersionsGrid(state)
    };
};

const withConnect = connect(mapStateToProps, {
    ...securityVersionEntityActions,
    ...modalActions
});

export default compose(withReducer, withConnect)(SecurityVersionMetadataPreviewModal);
