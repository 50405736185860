import { createSlice } from '@reduxjs/toolkit';
import { securityVersionEntityActions } from 'containers/App/modules/entities/securityVersions/securityVersionEntity.slice';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
	createResetGridReducer,
	createSetGridParamsReducer,
	createUpdateGridReducer,
	GRID_INITIAL_STATE,
	createSetGridParamsAction,
	createResetGridDataAction,
} from 'containers/Grid/grid.utils';


const SECURITY_VERSIONS_STATE_KEY = 'securityVersions';
const SECURITY_VERSIONS_GRID_STATE_KEY = 'securityVersionsGrid';
const SECURITY_VERSION_METADATA_PREVIEW_STATE_KEY = 'securityVersionMetadataPreview';
const setGridParams = createSetGridParamsAction(SECURITY_VERSIONS_GRID_STATE_KEY);
const resetGridData = createResetGridDataAction(SECURITY_VERSIONS_GRID_STATE_KEY);

export const INITIAL_STATE = {
	[SECURITY_VERSIONS_GRID_STATE_KEY]: { ...GRID_INITIAL_STATE },
	[SECURITY_VERSION_METADATA_PREVIEW_STATE_KEY]: null,
};

const securityVersionsSlice = createSlice({
	name: SECURITY_VERSIONS_STATE_KEY,
	initialState: INITIAL_STATE,
	reducers: {
		setSecurityVersionMetadataPreview: (state, action) => {
			state[SECURITY_VERSION_METADATA_PREVIEW_STATE_KEY] = action.payload;
		},
	},
	extraReducers: {
		//GRID
		[securityVersionEntityActions.fetchSecurityVersionsSuccess]: createUpdateGridReducer(SECURITY_VERSIONS_GRID_STATE_KEY),
		[setGridParams]: createSetGridParamsReducer(SECURITY_VERSIONS_GRID_STATE_KEY),
		[resetGridData]: createResetGridReducer(SECURITY_VERSIONS_GRID_STATE_KEY),
	}
});


const getSecurityVersionsState = state => state[SECURITY_VERSIONS_STATE_KEY] || INITIAL_STATE;

const selectSecurityVersionsGrid = createSelector(
	getSecurityVersionsState,
	securityVersionsState => {
		return securityVersionsState[SECURITY_VERSIONS_GRID_STATE_KEY]
	}
);

const selectSecurityVersionMetadataPreview = createSelector(
	getSecurityVersionsState,
	securityVersionsState => {
		return securityVersionsState[SECURITY_VERSION_METADATA_PREVIEW_STATE_KEY]
	}
);

const selectSecurityVersionIdsForPage = (state, props) => {
	const gridState = selectSecurityVersionsGrid(state);
	const pageNumber = _.get(gridState, 'params.pagination.current');
	return gridState.pages[pageNumber];
};

export const securityVersionSelectors = {
	selectSecurityVersionsGrid,
	selectSecurityVersionIdsForPage,
	selectSecurityVersionMetadataPreview
};

const {
	setSecurityVersionMetadataPreview,
} = securityVersionsSlice.actions;
export const securityVersionsActions = {
	setSecurityVersionMetadataPreview,
	setGridParams,
	resetGridData
};
export default securityVersionsSlice.reducer;