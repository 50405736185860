import { combineReducers } from 'redux';
import { ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import devicesReducer from 'containers/App/modules/entities/devices/deviceEntity.slice';
import userGroupsReducer from 'containers/App/modules/entities/userGroups/userGroupEntity.slice';
import deviceTypesReducer from 'containers/App/modules/entities/deviceTypes/deviceTypeEntity.slice';
import countriesReducer from 'containers/App/modules/entities/countries/countries.slice';
import alarmsReducer from 'containers/App/modules/entities/alarms/alarmsEntity.slice';
import deviceAlarmsReducer from 'containers/App/modules/entities/deviceAlarms/deviceAlarmsEntity.slice';
import deviceFilesReducer from 'containers/App/modules/entities/deviceFiles/deviceFilesEntity.slice';
import usersReducer from 'containers/App/modules/entities/users/userEntity.slice';
import customerDetailsReducer from 'containers/App/modules/entities/customerDetails/customerDetailsEntity.slice';
import groupsReducer from 'containers/App/modules/entities/groups/groupEntity.slice';
import licensesReducer from 'containers/App/modules/entities/licenses/licenseEntity.slice';
import swVersionsReducer from 'containers/App/modules/entities/swVersions/swVersionEntity.slice';
import securityVersionsReducer from 'containers/App/modules/entities/securityVersions/securityVersionEntity.slice';
import lumenisXVersionsReducer from 'containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice';
import v2cReducer from 'containers/App/modules/entities/v2c/v2cEntity.slice';

const reducer = combineReducers({
	[ENTITY_TYPES.ALARMS]: alarmsReducer,
	[ENTITY_TYPES.COUNTRIES]: countriesReducer,
	[ENTITY_TYPES.CUSTOMER_DETAILS]: customerDetailsReducer,
	[ENTITY_TYPES.DEVICES]: devicesReducer,
	[ENTITY_TYPES.DEVICE_TYPES]: deviceTypesReducer,
	[ENTITY_TYPES.GROUPS]: groupsReducer,
	[ENTITY_TYPES.SW_VERSIONS]: swVersionsReducer,
	[ENTITY_TYPES.SECURITY_VERSIONS]: securityVersionsReducer,
	[ENTITY_TYPES.USERS]: usersReducer,
	[ENTITY_TYPES.USER_GROUPS]: userGroupsReducer,
	[ENTITY_TYPES.V2C]: v2cReducer,
	[ENTITY_TYPES.LUMENISX_VERSIONS]: lumenisXVersionsReducer,
	[ENTITY_TYPES.DEVICE_ALARMS]: deviceAlarmsReducer,
	[ENTITY_TYPES.DEVICE_FILES]: deviceFilesReducer,
	[ENTITY_TYPES.LICENSES]: licensesReducer,
});

export default reducer;