/*
 * App Messages
 *
 * This contains all the text for the HomePage component.
 */
import { defineMessages } from "react-intl";

export const scope = "sidemenu";

export default defineMessages({
  home: {
    id: `${scope}.`,
    defaultMessage: "Home",
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: "Users",
  },
  devices: {
    id: `${scope}.devices`,
    defaultMessage: "Devices",
  },
  licenses: {
    id: `${scope}.licenses`,
    defaultMessage: "Licenses",
  },
  qlik: {
    id: `${scope}.qlik`,
    defaultMessage: "Qlik",
  },
  connectWise: {
    id: `${scope}.connectWise`,
    defaultMessage: "Connect Wise",
  },
  eventViewer: {
    id: `${scope}.eventViewer`,
    defaultMessage: "Event Viewer",
  },
  alarms: {
    id: `${scope}.alarms`,
    defaultMessage: "Alarms",
  },
  alarmsCount: {
    id: `${scope}.alarmsCount`,
    defaultMessage: "Alarms ({alarmsCount, number})",
  },
  fota: {
    id: `${scope}.fota`,
    defaultMessage: "Firmware Manager",
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: "Groups",
  },
  swVersions: {
    id: `${scope}.swVersions`,
    defaultMessage: "SW Versions",
  },
  securityVersions: {
    id: `${scope}.securityVersions`,
    defaultMessage: "Security Versions",
  },
  lumenisXVersions: {
    id: `${scope}.lumenisXVersions`,
    defaultMessage: "LumenisX Versions",
  },
  termsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: "Terms of Use",
  },
  userPrivacyNotice: {
    id: `${scope}.userPrivacyNotice`,
    defaultMessage: "User Privacy Notice",
  },
  switchTo: {
    id: `${scope}.switchTo`,
    defaultMessage: "Switch to",
  },
});
