import { defineMessages } from "react-intl";

export const scope = "swVersions";

export default defineMessages({
	type: {
		id: `${scope}.type`,
		defaultMessage: "Device Type",
	},
	version: {
		id: `${scope}.version`,
		defaultMessage: "Version",
	},
	uploadDate: {
		id: `${scope}.uploadDate`,
		defaultMessage: "Upload Date",
	},
	uploadedBy: {
		id: `${scope}.uploadedBy`,
		defaultMessage: "Uploaded By",
	},
	upload: {
		id: `${scope}.upload`,
		defaultMessage: "Upload",
	},
	comments: {
		id: `${scope}.comments`,
		defaultMessage: "Comments",
	},
	uploadVersion: {
		id: `${scope}.uploadVersion`,
		defaultMessage: "Upload Security Version",
	},
	deviceTypes: {
		id: `${scope}.deviceTypes`,
		defaultMessage: "Device Types",
	},
	deviceTypeNonSelectable: {
		id: `${scope}.deviceTypeNonSelectable`,
		defaultMessage: "Only Device Family or Device Type are assignable",
	},
	valid: {
		id: `${scope}.valid`,
		defaultMessage: 'Valid',
	},
	invalid: {
		id: `${scope}.invalid`,
		defaultMessage: 'Invalid',
	},
	fileType: {
		id: `${scope}.fileType`,
		defaultMessage: 'File Type',
	},
	supportedVersions: {
		id: `${scope}.supportedVersions`,
		defaultMessage: 'Supported Versions',
	},
	installType: {
		id: `${scope}.installType`,
		defaultMessage: 'Install Type',
	},
	updateSecurityVersionSuccess: {
		id: `${scope}.updateSecurityVersionSuccess`,
		defaultMessage: 'Updated security version successfully',
	},
	updateSecurityVersionError: {
		id: `${scope}.updateSecurityVersionError`,
		defaultMessage: 'Error updating security version',
	},
	deleteSecurityVersionHeader: {
		id: `${scope}.deleteSecurityVersionHeader`,
		defaultMessage: 'Delete Warning',
	},
	deleteSecurityVersionWarning: {
		id: `${scope}.deleteSecurityVersionWarning`,
		defaultMessage: 'This action will permanently render your current security version invalid by deleting the associated security patch files stored in the cloud.',
	},
	deleteSecurityVersionAreYouSure: {
		id: `${scope}.deleteSecurityVersionAreYouSure`,
		defaultMessage: 'Delete security version: ',
	},
	deleteSecurityVersionSuccess: {
		id: `${scope}.deleteSecurityVersionSuccess`,
		defaultMessage: 'Deleted security version successfully',
	},
	deleteSecurityVersionError: {
		id: `${scope}.deleteSecurityVersionError`,
		defaultMessage: 'Error deleting security version',
	},
	createSecurityVersionSuccess: {
		id: `${scope}.createSecurityVersionSuccess`,
		defaultMessage: 'Created a security version successfully',
	},
	createSecurityVersionError: {
		id: `${scope}.createSecurityVersionError`,
		defaultMessage: 'Error creating the security version',
	},
	getSecurityVersionMetadataPreviewError: {
		id: `${scope}.getSecurityVersionMetadataPreviewError`,
		defaultMessage: 'Error getting the metadata preview of the security version',
	},
	versionName: {
		id: `${scope}.versionName`,
		defaultMessage: 'Version Name',
	},
	size: {
		id: `${scope}.size`,
		defaultMessage: 'Size',
	},
	numOfPatches: {
		id: `${scope}.numOfPatches`,
		defaultMessage: 'Number of Patches',
	},
	patches: {
		id: `${scope}.patches`,
		defaultMessage: 'Patches',
	},
	dependencies: {
		id: `${scope}.dependencies`,
		defaultMessage: 'Dependencies',
	},
	fileName: {
		id: `${scope}.fileName`,
		defaultMessage: 'File Name',
	},
	delete: {
		id: `${scope}.delete`,
		defaultMessage: 'Delete',
	},
	deleted: {
		id: `${scope}.deleted`,
		defaultMessage: 'Deleted',
	},
});