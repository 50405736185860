import React from 'react';
// import { Helmet } from 'react-helmet';
import { Switch, Route, Router } from 'react-router-dom';
import history from 'utils/history';

import AppLayoutContainer from 'containers/App/containers/AppLayoutContainer';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import LoginPage from 'pages/LoginPage';
import AdfsLoginPage from 'pages/LoginPage/components/AdfsLoginPage';
import PrivateRoute from './PrivateRoute';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import AdfsLogoutCompleteContainer from 'pages/LoginPage/containers/AdfsLogoutCompleteContainer';

import GlobalStyle from '../../../global-styles';
import ModalRoot from 'containers/ModalRoot';
import { APP_ROUTES } from '../app.constants';
import AppConfig from 'config/AppConfig';

function App(props) {
	const {
		isLoggedIn,
	} = props;

	return (
		<>
			<GlobalStyle />
			<ModalRoot />
			<Router history={history}>
				<Switch>
					{AppConfig.LOGIN_VIA_ADFS ?
						<Route exact path={APP_ROUTES.LOGIN} component={() => { window.location.replace(`${AppConfig.API_URL}/ums/adfs/saml/login`) }} /> :
						<Route exact path={APP_ROUTES.LOGIN} component={LoginPage.component} />
					}
					<Route path={APP_ROUTES.ADFS_LOGIN} component={AdfsLoginPage} />
					<Route path={APP_ROUTES.ADFS_LOGOUT_COMPLETE} component={AdfsLogoutCompleteContainer} />
					<Route exact path={APP_ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage.component} />
					<Route exact path={APP_ROUTES.RESET_PASSWORD} component={ResetPasswordPage.component} />
					<PrivateRoute authed={isLoggedIn} path="/" component={AppLayoutContainer} />
				</Switch>
			</Router>
		</>
	);
}

export default App;