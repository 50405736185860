import React from 'react';
import styled from 'styled-components';
import env from '../../env';


const VersionContainer = styled.section`
	white-space: nowrap;
`;

const AppVersion = (props) => {
	return (
		<VersionContainer className={props.className}>
			{env.REACT_APP_VERSION}
		</VersionContainer>
	);
};

export default AppVersion;