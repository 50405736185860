import { call, takeLatest, put, select } from "redux-saga/effects";
import BackendService from "services/BackendService";
import { lumenisXVersionEntityActions } from "containers/App/modules/entities/lumenisXVersions/lumenisXVersionEntity.slice";
import { api } from "containers/App/modules/async.saga";
import schemas from "containers/App/modules/api/schema";
import { parseFetchAllSuccess } from "containers/App/modules/entities/lumenisXVersions/lumenisXVersions.parsers";
import {
	createEntitySuccess,
	updateEntityFailure,
} from "containers/App/modules/entities/entities.utils";
import { showSuccessToast } from "../../toasts/toasts.actions";
import { ENTITY_TYPES } from "../entities.constants";
import { calculateFileChecksum } from "../../app.saga";
import { lumenisXVersionSelectors } from '../../../../../pages/LumenisXVersions/modules/lumenisXVersions.slice';


function* fetchLumenisXVersions(action) {
	yield call(api, {
		apiFn: BackendService.fetchLumenisXVersions,
		parseSuccessResponseFn: parseFetchAllSuccess,
		schema: [schemas.lumenisXVersion],
		params: action.payload,
		actions: [
			lumenisXVersionEntityActions.fetchLumenisXVersionsPending,
			lumenisXVersionEntityActions.fetchLumenisXVersionsSuccess,
			lumenisXVersionEntityActions.fetchLumenisXVersionsFailure,
		],
	});
}

function* onUploadLumenisXVersion(action) {
	const params = action.payload;
	const file = params.upload[0];

	yield put(lumenisXVersionEntityActions.createLumenisXVersionPending());

	const uploadUrlData = yield call(BackendService.getUploadUrl);
	const { url, versionId } = uploadUrlData.data;
	const fileToUpload = new File([file.originFileObj], versionId, {
		type: file.originFileObj.type
	});

	yield call(BackendService.uploadFile, url, fileToUpload);

	const checksum = yield call(calculateFileChecksum, fileToUpload);

	const data = {
		comments: params.comments,
		name: params.version,
		checksum,
		algorithm: 'sha256',
		fileSize: fileToUpload.size,
	}

	yield call(api, {
		apiFn: BackendService.createLumenisXVersion,
		params: { data, versionId },
		actions: [
			lumenisXVersionEntityActions.createLumenisXVersionPending,
			lumenisXVersionEntityActions.createLumenisXVersionSuccess,
			lumenisXVersionEntityActions.createLumenisXVersionFailure,
		],
	});
}

function* updateLumenisXVersion(action) {
	const data = action.payload;
	const versionId = action.payload.versionId
	const refresh = action.payload.refresh;
	delete data.versionId;
	delete data.refresh;

	yield call(api, {
		apiFn: BackendService.patchLumxVersion,
		params: { data, versionId },
		actions: [
			lumenisXVersionEntityActions.updateLumenisXVersionPending,
			lumenisXVersionEntityActions.updateLumenisXVersionSuccess,
			lumenisXVersionEntityActions.updateLumenisXVersionFailure
		]
	});
	yield call(refresh);
}

function* updateLumenisXVersionSuccess() {
	yield put(showSuccessToast({ title: 'Version updated successfully' }));
	const lumenisXVersionsGrid = yield select(lumenisXVersionSelectors.selectLumenisXVersionsGrid);
	yield put(lumenisXVersionEntityActions.fetchLumenisXVersions(lumenisXVersionsGrid.params));
}

export default function* watchLumenisXVersionsSaga() {
	yield takeLatest(lumenisXVersionEntityActions.fetchLumenisXVersions, fetchLumenisXVersions);

	yield takeLatest(lumenisXVersionEntityActions.createLumenisXVersion, onUploadLumenisXVersion);
	yield takeLatest(lumenisXVersionEntityActions.createLumenisXVersionSuccess, createEntitySuccess, ENTITY_TYPES.LUMENISX_VERSIONS);

	yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersion, updateLumenisXVersion);
	yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersionSuccess, updateLumenisXVersionSuccess);
	yield takeLatest(lumenisXVersionEntityActions.updateLumenisXVersionFailure, updateEntityFailure);

}
