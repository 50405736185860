import { createNewEntitySlice } from 'containers/App/modules/entities/entities.utils';
import { parseFetchAllRequest, prepareUpdateAssignedDevicesRequest, prepareUpdateAssignedDevicesWithAllModeRequest, parseCreateRequest } from './group.parsers';
import { NOOP } from 'containers/App/app.constants';
import { createSelector } from '@reduxjs/toolkit';
import { ENTITIES_STATE_KEY, ENTITY_TYPES } from 'containers/App/modules/entities/entities.constants';
import _ from 'lodash';

export const STATE_KEY = ENTITY_TYPES.GROUPS;

const extraAsyncActions = [
	{
		type: 'updateAssignedDevices',
		requestPrepareFn: prepareUpdateAssignedDevicesRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'updateAssignedDevicesWithAllMode',
		requestPrepareFn: prepareUpdateAssignedDevicesWithAllModeRequest,
		setStateDataFn: NOOP
	},
	{
		type: 'fetchVersions',
		setStateDataFn: (state, action) => {
			const { lumXVersions, swVersions, securityVersions } = action.payload.data;

			state.potentialVersions = {
				lumXVersions: lumXVersions.versions,
				swVersions: swVersions.versions,
				securityVersions: securityVersions.versions
			};

			return state;
		}
	},
	{
		type: 'publishVersionToGroup',
	},
	{
		type: 'fetchGroupDevices',
		setStateDataFn: (state, action) => {
			state.groupDevices = action.payload.data.data;

			return state;
		}
	},
]

const groupEntitySlice = createNewEntitySlice({
	name: STATE_KEY,
	extraAsyncActions,
	parseFetchAllRequest,
	parseCreateRequest
});

const { reducer, actions, selectors } = groupEntitySlice;
const {
	fetchAll: fetchGroups,
	fetchAllPending: fetchGroupsPending,
	fetchAllSuccess: fetchGroupsSuccess,
	fetchAllFailure: fetchGroupsFailure,

	fetchById: fetchGroup,
	fetchByIdPending: fetchGroupPending,
	fetchByIdSuccess: fetchGroupSuccess,
	fetchByIdFailure: fetchGroupFailure,

	create: createGroup,
	createPending: createGroupPending,
	createSuccess: createGroupSuccess,
	createFailure: createGroupFailure,

	update: updateGroup,
	updatePending: updateGroupPending,
	updateSuccess: updateGroupSuccess,
	updateFailure: updateGroupFailure,

	updateAssignedDevices,
	updateAssignedDevicesPending,
	updateAssignedDevicesSuccess,
	updateAssignedDevicesFailure,

	updateAssignedDevicesWithAllMode,
	updateAssignedDevicesWithAllModePending,
	updateAssignedDevicesWithAllModeSuccess,
	updateAssignedDevicesWithAllModeFailure,

	fetchVersions,
	fetchVersionsPending,
	fetchVersionsSuccess,
	fetchVersionsFailure,

	publishVersionToGroup,
	publishVersionToGroupPending,
	publishVersionToGroupSuccess,
	publishVersionToGroupFailure,

	fetchGroupDevices,
	fetchGroupDevicesPending,
	fetchGroupDevicesSuccess,
	fetchGroupDevicesFailure,
} = actions;

export const groupEntityActions = {
	fetchGroups,
	fetchGroupsPending,
	fetchGroupsSuccess,
	fetchGroupsFailure,

	fetchGroup,
	fetchGroupPending,
	fetchGroupSuccess,
	fetchGroupFailure,

	createGroup,
	createGroupPending,
	createGroupSuccess,
	createGroupFailure,

	updateGroup,
	updateGroupPending,
	updateGroupSuccess,
	updateGroupFailure,

	updateAssignedDevices,
	updateAssignedDevicesPending,
	updateAssignedDevicesSuccess,
	updateAssignedDevicesFailure,

	updateAssignedDevicesWithAllMode,
	updateAssignedDevicesWithAllModePending,
	updateAssignedDevicesWithAllModeSuccess,
	updateAssignedDevicesWithAllModeFailure,

	fetchVersions,
	fetchVersionsPending,
	fetchVersionsSuccess,
	fetchVersionsFailure,

	publishVersionToGroup,
	publishVersionToGroupPending,
	publishVersionToGroupSuccess,
	publishVersionToGroupFailure,

	fetchGroupDevices,
	fetchGroupDevicesPending,
	fetchGroupDevicesSuccess,
	fetchGroupDevicesFailure,
};

const getEntitiesState = state => state[ENTITIES_STATE_KEY] || {};

const getState = createSelector(
	getEntitiesState,
	state => _.get(state, STATE_KEY)
);

const selectGroupPotentialVersions = createSelector(
	getState,
	state => _.get(state, 'potentialVersions')
);

const selectGroupDevices = createSelector(
	getState,
	state => _.get(state, 'groupDevices')
);

const selectFetchVersionsPending = createSelector(
	getState,
	state => _.get(state, 'fetchVersionsLoading')
);

const selectFetchGroupDevicePending = createSelector(
	getState,
	state => _.get(state, 'fetchGroupDevicesLoading')
);

const {
	selectAll: selectGroups,
	selectFetchAllPending: selectGroupsPending,
	createSelectByIdOrIds: createSelectGroupsByIdOrIds,
	selectCreatePending: selectCreateGroupPending,
	selectFetchByIdPending: selectFetchGroupPending,
} = selectors;


export const groupEntitySelectors = {
	selectGroups,
	selectGroupsPending,
	createSelectGroupsByIdOrIds,
	selectCreateGroupPending,
	selectFetchGroupPending,
	selectGroupPotentialVersions,
	selectFetchVersionsPending,
	selectFetchGroupDevicePending,
	selectGroupDevices,
};

export default reducer;